<template>
<div class="row mt-5 mb-10">
  <div class="col-12">
    <div class="card card-custom h-100">
      <div class="card-body" >
       <div class="mb-5">
          <GmapMap
            :center="myCoordinates"
            :zoom="5"
            ref="mapRef"
            :options="{
               zoomControl: true,
               mapTypeControl: false,
               scaleControl: false,
               streetViewControl: false,
               rotateControl: false,
               fullscreenControl: false,
               disableDefaultUi: false
             }"
            @dragend="handleDrag"
            style="width: 100%; height: 600px; "
          >
            <GmapCluster :gridSize="30" :zoomOnClick="true">

              <GmapMarker :key="index" :title="index.toString()" v-for="(marker, index) in markers" :position="marker.position" :clickable="true" @click="toggleInfoWindow(marker, index)"></GmapMarker>

              <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
                <GmapInfoWindowCard :idea="infoContent" @addFollower="addFollower($event)" @delFollower="delFollower($event)"></GmapInfoWindowCard>
              </GmapInfoWindow>

            </GmapCluster>
          </GmapMap>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import ApiService from "@/core/services/api.service";
import { gmapApi } from 'gmap-vue';
import GmapCluster from 'gmap-vue/dist/components/cluster'
import { ideaMixin } from "@/mixins/idea";
import GmapInfoWindowCard from '@/view/pages/modules/ideas/partials/GmapInfoWindowCard.vue';

export default {
  name: "MapIdeaBlock",
  mixins: [ideaMixin],
  props: ["ideas", "process_id", "process", "phase", "phase_id", "phase_status_id"],
  data() {
    return {
      bounds: null,
      module: [],
      map: null,
      myCoordinates: {
        lat: 0,
        lng: 0
      },
      zoom: 5,
      markers: [],
      infoContent: null,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
       pixelOffset: {
          width: 0,
          height: -35
        }
      },
      modulesWithVotes: [],
    };
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ]),
    google: gmapApi,
  },

  components: {
    GmapCluster,
    GmapInfoWindowCard
  },

  watch: {
    ideas: {
      async handler() {
        await this.setMarkers();
      },
      deep: true
    },
  },

  async created() {

    if (this.process.cities[0]) {
      let cityPoint = this.process.cities[0].centroid;
      this.myCoordinates = this.pointToCoordinates(cityPoint);
    } else if (localStorage.center) {
      this.myCoordinates = JSON.parse(localStorage.center);
    } else {
      this.$getLocation({})
      .then(coordinates => {
        this.myCoordinates = coordinates;
      })
      .catch(() => {
        this.myCoordinates = this.$defaultCoordinates;
      })
    }

    if (localStorage.zoom) {
      this.zoom = parseInt(localStorage.zoom);
    }
  },

  mounted() {
    this.setModulesWithVotes(this.phase);
    this.$refs.mapRef.$mapPromise.then(map => this.map = map);
  },

  methods: {
    async setMarkers() {

      this.markers = [];
      if(this.ideas != null && this.google != null) {
        this.bounds = new this.google.maps.LatLngBounds();

        for (var i = 0; i < this.ideas.length; i++) {
          var item = this.ideas[i];
          item.idIdeaRouter = item.id;

          if (item.centroid) {

            if (item.existsUnpubVersionInGroup == true && item.author.id == this.currentUser.id && this.module && this.module.canVersion) {

              let qs = "?versionGroup.equals=" + item.versionGroup + "&ideaStatusId.equals=1";

              let endpoint = this.generateUrl('ideas', false);
              //generateUrl is done
              await ApiService.get(this.$apiResourceOpen, endpoint + qs)
              .then(res => {

                if (res.data && res.data[0]) {

                  item.idIdeaRouter = res.data[0].id;
                }
              })
            }

            let markerPosition = this.pointToCoordinates(item.centroid);
            let markerIdea = item;

            if(this.markers.some(x => (x.position.lat == markerPosition.lat && x.position.lng == markerPosition.lng) )) {

              this.markers.forEach((element) => {

                if(element.position.lat == markerPosition.lat && element.position.lng == markerPosition.lng) {

                  markerPosition.lat += 0.0001;
                  markerPosition.lng += 0.0001;
                }
              })
            }

            if(!this.markers.some(m => (m.markerIdea.idIdeaRouter == item.idIdeaRouter) )) {
              this.markers.push({
                position: markerPosition,
                markerIdea: markerIdea
              });
            }
            this.bounds.extend(markerPosition)
          }
        }
        this.$refs.mapRef.fitBounds(this.bounds)
      }
    },
    handleDrag() {
      let center = {
        lat: this.map.getCenter().lat(),
        lng: this.map.getCenter().lng(),
      };
      let zoom = this.map.getZoom();

      localStorage.center = JSON.stringify(center);
      localStorage.zoom = zoom;

    },
    toggleInfoWindow (marker, idx) {

      this.module = this.getCurrentModule(this.process, marker?.markerIdea?.process2ModuleId);
      this.module_id = this.module?.id;

      this.infoWindowPos = marker.position;
      this.infoContent = marker;
      this.infoContent.params = {};
      this.infoContent.params.process_id = this.process_id;
      this.infoContent.params.process = this.process;
      this.infoContent.params.phase = this.phase;
      this.infoContent.params.phase_id = this.phase_id;
      this.infoContent.params.phase_status_id = this.phase_status_id;
      this.infoContent.params.module_id = this.module_id;
      this.infoContent.params.module = this.module;
      this.infoContent.params.modulesWithVotes = this.modulesWithVotes;

      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    setModulesWithVotes (phase) {
      for ( let i = 0; i < phase.processVotes.length; i++) {
        if ( phase.processVotes[i].open && ! this.modulesWithVotes.includes(phase.processVotes[i].process2ModuleId)) {
          this.modulesWithVotes.push(phase.processVotes[i].process2ModuleId);
        }
      }
    },

    truncate(text, length, clamp){
      clamp = clamp || '...';
      let node = document.createElement('div');
      node.innerHTML = text;
      let content = node.textContent;
      return content.length > length ? content.slice(0, length) + clamp : content;
    },

    addFollower(event) {
      let incFollowerObj = {
        idea: event,
        module: this.module,        
      }
      this.$emit("incFollower", incFollowerObj);
    },
    delFollower(event) {
      let decFollowerObj = {
        idea: event,
        module: this.module,        
      }
      this.$emit("decFollower", decFollowerObj);
    }
  },
}
</script>

<style scoped></style>