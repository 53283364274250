var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex align-items-center mb-8"},[_c('div',{staticClass:"symbol symbol-circle symbol-50 symbol-light mr-3 flex-shrink-0"},[(_vm.module.logo)?_c('span',{staticClass:"symbol-label",style:(`background: url(${_vm.module.logo.uri}) no-repeat center; background-size: cover;`)}):_c('span',{staticClass:"symbol-label"}),_c('i',{staticClass:"symbol-badge",class:{'bg-success': _vm.isOpen, 'bg-danger': !_vm.isOpen},staticStyle:{"width":"15px !important","height":"15px !important","bottom":"6px !important","top":"auto !important"}})]),_c('div',[_c('span',{staticClass:"font-size-h6 text-dark-75 text-hover-primary font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.module.localizations[0].title)+" ")])])]),_c('div',{staticClass:"d-flex flex-row justify-content-between align-items-center"},[_c('span',[_c('span',{staticClass:"h4 font-weight-bolder"},[_vm._v(" "+_vm._s(this.numberCurrentVersions))]),_vm._v(" "+_vm._s(_vm.$t(("PHASEMODULECARD" + this.$handleContentTypeLabel(this.moduleContentType) + ".IDEE"))))]),(_vm.phase.open)?_c('router-link',{attrs:{"to":{
            name: _vm.getModuleCall,
            params: {
              id: _vm.module.id,
              process_id: _vm.module.process.id,
              phase_id: _vm.phase.id
            }
          }}},[_c('div',{staticClass:"d-flex flex-row btn btn-md btn-primary font-weight-bolder text-uppercase rounded align-items-center"},[_c('span',{},[_vm._v(_vm._s(_vm.$t("PARTIALSPROCESSI.PARTECIPA")))])])]):_c('router-link',{attrs:{"to":{
            name: _vm.getModuleCall,
            params: {
              id: _vm.module.id,
              process_id: _vm.module.process.id,
              phase_id: _vm.phase.id
            }
          }}},[_c('div',{staticClass:"d-flex flex-row btn btn-md btn-primary font-weight-bolder text-uppercase rounded align-items-center ml-10"},[_c('span',{},[_vm._v(_vm._s(_vm.$t("PARTIALSPROCESSI.VISUALIZZA")))])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }