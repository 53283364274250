<template>
  <div class="card card-custom zoom ribbon ribbon-clip ribbon-right">

    <div class="card-body py-5 ribbon ribbon-top ribbon-ver">
      <div
          class="ribbon-target bg-warning" style="top: -2px; right: 20px;"
          v-if="votedIdea()">
        <i class="fa fa-star text-white"></i>

      </div>

      <!--Ribbon Assessment-->
      <router-link v-if="idIdeaRouter"
          :to="{
            name: 'processModuleIdeaDetail',
            query: {
              forced_evaluate: true
            },
            params: {
              id: idIdeaRouter,
              process_id: process_id,
              phase_id: phase_id,
              module_id: module_id,
              phase_status_id: phase_status_id
              }
            }"
      >
        <div class="ribbon-target p-3" style="bottom: 20px;" v-if="publishAssessmentsMaster && ideaSimpleAssessmentStatusId > 1">
          <span
              class="ribbon-inner"
              :style="'color:#fff; background-color:' + ideaSimpleAssessmentStatusColor"
          ></span>
          <span class=" text-uppercase font-weight-bolder">{{assessment}}</span>
        </div>
      </router-link>
      <!--end::Ribbon Assessment-->

      <!--Ribbon Versioning-->
      <div v-if="(this.idea.versionNumber && this.idea.versionNumber > 0) && this.module && this.module.activeVersioning" class="ribbon-target p-3" style="top: 12px;">
        <span class="ribbon-inner bg-primary"></span>
        <span class="text-uppercase font-weight-bolder h3">{{this.idea.versionNumber}}</span>
      </div>
      <!--end::Ribbon Versioning-->


      <div class="d-flex">
          <div class="flex-start symbol symbol-50 symbol-lg-120 mr-5" style="width: 50%; max-width: 150px; max-height: 150px;">
              <div class="w-100 h-100" :style="'background: url(' + (idea.logo ? idea.logo.uri : '/assets/media/bipart/idea-default.svg') + ') no-repeat center; background-size: cover;'"></div>

<!--            <img-->
<!--                :src="-->
<!--                idea.logo-->
<!--                ? idea.logo.uri-->
<!--                : '/assets/media/bipart/idea-default.svg'-->
<!--              "-->
<!--                alt=""-->
<!--                class="w-100"-->
<!--            />-->
<!--            #qui-->
          </div>
          <div class="align-items-center">
            <div class="mt-2 d-flex flex-start">
              <div class="mr-3">
                <router-link v-if="idIdeaRouter"
                    class="cursor-pointer"
                    :to="{
              name: 'processModuleIdeaDetail',
              params: {
                id: idIdeaRouter,
                process_id: process_id,
                phase_id: phase_id,
                module_id: module_id,
                phase_status_id: phase_status_id
                }
              }">
                  <div class="d-flex align-items-center text-dark text-primary font-size-h3 font-weight-bold mr-3">{{ idea.title | truncate(50, '...') }}
                    <span
                        class="label label-lg label-pill label-inline mr-2"
                        v-bind:class="{
                    'label-light-warning': idea.ideaStatus.id != 3,
                    'label-light-primary': idea.ideaStatus.id == 3
                  }"
                        v-if="idea.ideaStatus.id == 2"
                    >{{idea.ideaStatus.name}}</span>
                  </div>
                </router-link>
                <div class="flex-grow-1 font-weight-bold text-dark-50 py-2 py-lg-2 mr-5 lead">{{ idea.description | truncate(90, '...') }}</div>
                <div class="d-flex flex-wrap my-2 font-size-h6">
                  <!-- Creata da <a href="#" class="text-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2 ml-2">
                  {{idea.author.username}}</a> -->
                <span class="text-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2" v-if="idea.budgetMin">
                  <i class="fas fa-coins mr-3 text-primary "></i> {{idea.budgetMin}} €
                </span>
                <span class="text-muted text-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2" v-if="displayViewMap">
                  <i class="fas fa-map-marked-alt text-primary"></i>
                </span>
                </div>
              </div>
            </div>
            <div class="flex-end d-flex align-items-center mt-lg-0 mt-3">

              <span v-if="this.module && this.module.activeCommenting" class="p-2 mr-1 mt-2"><i class="fas fa-comment text-primary"></i> {{idea.countIdeaComments}}</span>

              <div v-if="$canFollow">
                <template v-if="idea.followedByAuthUser">
                  <button @click="delFollower()" class="btn btn-sm btn-default btn-text-primary mr-2 mt-2">
                    <i class="fas fa-heart text-danger"></i>{{idea.countFollowers}}
                  </button>
                </template>
                <template v-else>
                  <button @click="addFollower()" class="btn btn-sm btn-default btn-text-primary mr-2 mt-2">
                    <i class="fas fa-heart text-primary"></i>
                  {{idea.countFollowers}}
                  </button>
                </template>
              </div>
              <span v-else-if="$activeFollowing" class="mr-3 mt-2"><i class="fas fa-heart text-danger"></i> {{idea.countFollowers}}</span>

              <!-- ID 2 - PUBLIC - VOTED -->
              <div class="d-flex flex-row" v-if="phase.processVotes.length > 0 && modulesWithVotes.includes(module_id)">
                <router-link v-if="idIdeaRouter"
                    :to="{
                    name: 'processModuleIdeaDetail',
                      params: {
                        id: idIdeaRouter,
                        process_id: process_id,
                        phase_id: phase_id,
                        module_id: module_id,
                        phase_status_id: phase_status_id
                        }
                    }"
                  class="mr-2"
                >
                  <span class="mt-1 btn btn-primary btn-block font-weight-bolder text-uppercase" >{{$t("PARTIALSPROCESSI.VOTA")}}</span>
                </router-link>
              </div>
              <div class="d-flex flex-row" v-else>
                <router-link v-if="idIdeaRouter"
                    :to="{
                    name: 'processModuleIdeaDetail',
                      params: {
                        id: idIdeaRouter,
                        process_id: process_id,
                        phase_id: phase_id,
                        module_id: module_id,
                        phase_status_id: phase_status_id
                        }
                    }"
                  class="mr-2"
                >
                  <span class="mt-1 btn bg-warning text-white btn-block font-weight-bolder text-uppercase" >{{$t("PARTIALSPROCESSI.VISUALIZZA")}}</span>
                </router-link>
              </div>

            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ApiService from "@/core/services/api.service";
import { ideaMixin } from "@/mixins/idea";

export default {
  name: "ProcessListIdeaCard",
  mixins: [ideaMixin],
  props: ["idea", "process_id", "process", "phase", "phase_id", "phase_status_id", "module_id"],

  data() {
    return {
      idIdeaRouter: null,
      displayViewMap: false,
      assessment: '',
      ideaSimpleAssessmentStatusId: 1,
      ideaSimpleAssessmentStatusColor: '',
      publishAssessmentsMaster: false,
      module: [],
      modulesWithVotes: [],
      ongoingPrivateRoles : ['ASSESSOR', 'EXPERT', 'DELEGATE'],
      allAuthRoles: [
        'ADMIN', 'FACILITATOR', 'ASSESSOR', 'EXPERT', 'DELEGATE', 'AUTH_CITIZEN'
      ],
      adminRoles: [
        'ADMIN', 'FACILITATOR'
      ],
      evaluateRoles: [
        'ASSESSOR', 'EXPERT'
      ]
    }
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ])
  },

  async mounted(){
    this.setModulesWithVotes(this.phase);
    this.module = this.getCurrentModule(this.process, this.module_id);

    if(this.idea && this.module) {

      this.publishAssessmentsMaster = this.module.publishAssessmentsMaster || (this.idea && this.idea.assessor && this.idea.assessor.id && this.idea.assessor.id == this.currentUser.id);

      this.ideaSimpleAssessmentStatusColor = this.idea.ideaSimpleAssessmentStatus.color ?? '';
      this.ideaSimpleAssessmentStatusId = this.idea.ideaSimpleAssessmentStatus.id ?? 1;
      this.assessment = this.idea.ideaSimpleAssessmentStatus.localizations[0].name ?? '';

      this.displayViewMap = ((this.idea?.centroid && this.idea?.centroid != null) && this.process?.cities?.length > 0);

      this.idIdeaRouter = this.idea.id;
    }

    if (this.idea && this.idea.existsUnpubVersionInGroup == true && this.idea.author.id == this.currentUser.id && this.module && this.module.canVersion) {

      let qs = "?versionGroup.equals=" + this.idea.versionGroup + "&ideaStatusId.equals=1";

      let endpoint = this.generateUrl('ideas', false);
      //generateUrl is done
      await ApiService.get(this.$apiResourceOpen, endpoint + qs)
      .then(res => {

        if(res.data && res.data[0]) {

          this.idIdeaRouter = res.data[0].id;
        }
      })
    }
  },

  methods: {
    addFollower() {
      let incFollowerObj = {
        idea: this.idea.id,
        module: this.module,        
      }
      this.$emit("incFollower", incFollowerObj);
    },
    delFollower() {
      let decFollowerObj = {
        idea: this.idea.id,
        module: this.module,        
      }
      this.$emit("decFollower", decFollowerObj);
    },

    setModulesWithVotes (phase) {
      for ( let i = 0; i < phase.processVotes.length; i++) {
        if ( phase.processVotes[i].open && ! this.modulesWithVotes.includes(phase.processVotes[i].process2ModuleId)) {
          this.modulesWithVotes.push(phase.processVotes[i].process2ModuleId);
        }
      }
    },

    votedIdea(){
      if(this.idea.idea2Votes) {
        for (let i = 0; i < this.idea.idea2Votes.length; i++) {
          if (this.idea.idea2Votes[i].voter.id == this.currentUser.id) {
            return true;
          }
        }
      }
      return false;
    }

  },
  filters:{
    truncate(text, length, clamp){
      clamp = clamp || '...';
      let node = document.createElement('div');
      node.innerHTML = text;
      let content = node.textContent;
      return content.length > length ? content.slice(0, length) + clamp : content;
    }
  }
}
</script>

<style scoped>

</style>
