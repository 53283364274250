<template>
  <div>
    <div class="d-flex align-items-center mb-8">
      <div class="symbol symbol-circle symbol-50 symbol-light mr-3 flex-shrink-0">
<!--        #originale -->
<!--        <span class="symbol-label">-->
<!--          <img-->
<!--              v-if="module.logo"-->
<!--              :src="module.logo.uri"-->
<!--              class="h-75 align-self-end" alt="">-->
<!--        </span>  -->
<!--        #n: gianpaolo-->
        <span class="symbol-label" v-if="module.logo" :style="`background: url(${module.logo.uri}) no-repeat center; background-size: cover;`"></span>
        <span class="symbol-label" v-else></span>

        <i class="symbol-badge" :class="{'bg-success': isOpen, 'bg-danger': !isOpen}" style="width: 15px !important;height: 15px !important; bottom: 6px !important; top: auto !important;"></i>
      </div>
      <div>
        <span class="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">
          {{module.localizations[0].title}}
        </span>
        <!--                  Titolo del modulo Authors with the best sales molto lungooooo-->
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between align-items-center">
      <span><span class="h4 font-weight-bolder"> {{ this.numberCurrentVersions }}</span> {{$t(("PHASEMODULECARD" + this.$handleContentTypeLabel(this.moduleContentType) + ".IDEE"))}}</span>

      <!-- PHASE OPEN -->
      <router-link
          v-if="phase.open"
          :to="{
            name: getModuleCall,
            params: {
              id: module.id,
              process_id: module.process.id,
              phase_id: phase.id
            }
          }"
      >
        <!-- Join phase -->
        <div class="d-flex flex-row btn btn-md btn-primary font-weight-bolder text-uppercase rounded align-items-center">
          <span class="">{{$t("PARTIALSPROCESSI.PARTECIPA")}}</span>
        </div>
      </router-link>

      <!-- PHASE CLOSED -->
      <router-link
          v-else
          :to="{
            name: getModuleCall,
            params: {
              id: module.id,
              process_id: module.process.id,
              phase_id: phase.id
            }
          }"
      >
        <!-- Publish Results -->
        <div class="d-flex flex-row  btn btn-md btn-primary font-weight-bolder text-uppercase rounded align-items-center ml-10">
          <span class="">{{$t("PARTIALSPROCESSI.VISUALIZZA")}}</span> </div>
      </router-link>

    </div>
  </div>
</template>

<script>

import ApiService from "@/core/services/api.service";

export default {
  name: "PhaseModuleCard",
  props: ["phase", "module"],
  data() {
    return {
      modulesWithVotes: [],
      numberCurrentVersions: 0,
      openDate: null,
      closeDate: null,
      isOpen: false,
      moduleContentType: null,
    }
  },
  computed: {
    getModuleCall() {
      let moduleName = "";
      switch (this.module.module.id) {
        case 20: {
          moduleName = "processModuleIdeaList";
          break;
        }
        default: {
          moduleName = "processModuleInProgress";
          break;
        }
      }
      // console.log('moduleName = ' + moduleName);
      return moduleName;
    },
  },
  created() {
    this.countCurrentVersions();

    if(this.module) {
      this.openDate = this.module.openDate || null;
      this.closeDate = this.module.closeDate || null;

      if(this.openDate && this.closeDate) {
        this.openDate = new Date(this.openDate);
        this.closeDate = new Date(this.closeDate);
        let now = new Date();

        if(now>=this.openDate && now<=this.closeDate) {
          this.isOpen = true;
        }
      }

      this.moduleContentType = this.module?.contentType;
    }
  },
  mounted(){
    this.setModulesWithVotes(this.phase);
  },
  methods: {
    setModulesWithVotes (phase) {
      for ( let i = 0; i < phase.processVotes.length; i++) {
        if ( phase.processVotes[i].open && ! this.modulesWithVotes.includes(phase.processVotes[i].process2ModuleId)) {
          this.modulesWithVotes.push(phase.processVotes[i].process2ModuleId);
        }
      }
    },
    countCurrentVersions() {
      return new Promise((resolve, reject) => {
        ApiService.get(this.$apiResource, "ideas/count?versionCurrent.equals=true&process2ModuleId.equals=" + this.module.id)
            .then(res => {
              // console.log("Numero versioni correnti: ", res);
              this.numberCurrentVersions = res.data;
              resolve(1);
            })
            .catch(({error}) => {
              console.log("Not good man :((" + error.data.error);
              reject(0);
            })
      });
    },

  }
}
</script>

<style scoped>

</style>
